<template>
	<v-app>
		<v-row v-if="intBreakPoint >= 2 && !isTrabalho" style="
				margin: -5px !important;
				z-index: 9;
				background-color: transparent;
				position: fixed;
				right: 0;
				left: 0;
			">
			<v-col v-if="isHome" cols="12" md="4"></v-col>
			<v-col v-if="!isHome" cols="12" md="4" class="d-flex justify-center align-center">
				<router-link to="/teste">
					<v-btn style="
							background-color: #d31f2ce6;
							color: #fff;
							font-family: Sofia pro, Roboto;
							z-index: 99999 !important;
							font-weight: 700;
							border-radius: 15px;
							text-transform: inherit;
							padding: 30px 15px;
							font-size: 18px !important;
							border: 2px solid #d4af37;
						">
						TESTE DE NÍVEL GRÁTIS!</v-btn>
				</router-link>
			</v-col>

			<v-col v-if="intBreakPoint >= 2" cols="2" md="4" class="d-flex justify-center align-center">
				<div class="justify-center align-center d-flex" style="
						background-color: #ffffffa3;
						border-radius: 0 0 20px 15px;
						position: absolute;
						z-index: 9;
						height: 100px;
						width: 150px;
						line-height: 2em;
						top: 0;
					">
					<router-link to="/">
						<v-img src="./assets/logaohugle.webp" width="125px"></v-img>
					</router-link>
				</div>
			</v-col>
			<v-col v-if="isHome" cols="12" md="4"></v-col>
			<v-col v-if="!isHome" cols="12" md="4" class="d-flex justify-center align-start">
				<div>
					<v-select @change="onChange(items.telefone)" dark hide-details="true" class="purple_bg"
						menu-props="{class:purple}" background-color="#d31f2ce6" style="
							border-radius: 50px;
							font-size: 13px;
							font-weight: 800;
							color: #d31f2ce6;
							padding: 10px 10px;
						" label="WhatsApp unidades" :items="items" v-model="selecao" item-text="cidade" item-value="telefone"
						v-on:change="changeRoute" dense solo>
						<template v-slot:item="slotProps">
							<i :class="[slotProps.item.img]"></i>
							{{ slotProps.item.cidade }}
						</template></v-select>
				</div>
			</v-col>
		</v-row>

		<!-- <v-row v-if="intBreakPoint < 2 " justify="center" align="center">
      <v-col cols="12" md="12" class="d-flex justify-center align-center flex-column" style="padding:0px">
        <div class="justify-center align-center d-flex" style="background-color:#ffffffa3;
        border-radius: 0 0 20px 15px;
        position: absolute;
        z-index: 9;
        margin-top:0px;
        padding:0;
        height:100px;
        width: 150px;
        line-height: 2em;
        top: 0;
         ">
          <router-link to="/">
            <v-img src="./assets/logaohugle.webp" width="125px"></v-img>
          </router-link>
        </div>
      </v-col>
    </v-row> -->

		<v-app-bar v-if="(intBreakPoint < 2) & !isHome" style="
				background-color: #d31f2cba;
				box-shadow: none;
				margin-top: 0px;
				margin-bottom: 0px;
				
			">
			<v-app-bar-nav-icon style="color: #fff; margin-left: 0px !important" class="ml-3" @click.stop="drawer = !drawer">
			</v-app-bar-nav-icon>

			<v-col cols="4" md="2" style="padding-left: 47px">
				<div class="alinhadoM">
					<router-link to="teste">
						<v-btn style="
								color: #d31f2c;
								font-family: usual;
								font-weight: 600;
								background-color: #fff;
								border-radius: 50px;
								opacity: 0.9;
								margin-top: 10px;
								font-size: 12px;
							">
							Teste seu nível
						</v-btn>
					</router-link>
				</div>
			</v-col>

			<v-col cols="4" md="2">
				<div class="alinhadoM" style="width: 116px !important">
					<v-select @change="onChange(items.telefone)" hide-details="true" background-color="#fff" color="#D31F2C" style="
							color: #d31f2c;
							font-family: usual;
							font-weight: 600;
							background-color: #fff;
							border-radius: 50px;
							width: 116px !important;
							opacity: 0.9;
							margin-top: 10px;
							font-size: 12px;
						" placeholder="Whatsapp" :items="items" v-model="selecao" item-text="cidade" item-value="telefone"
						v-on:change="changeRoute" dense solo><template v-slot:item="slotProps">
							<i :class="[slotProps.item.img]"></i>
							{{ slotProps.item.cidade }}
						</template>
						Whatsapp
					</v-select>
				</div>
			</v-col>
		</v-app-bar>

		<div v-if="intBreakPoint >= 2">
			<VueSidebarMenuAkahon v-if="!isHome" :menuItems="Itens" :isMenuOpen="false" :isSearch="false" :isExitButton="false"
				:menuTitle="titulo" :profileImg="null" :profileName="null" :profileRole="null" :bgColor="Cor"
				:secondaryColor="sCor" :menuItemsTextColor="fontCor" :iconsColor="fontIcon" :menuItemsHoverColor="hCor"
				:itemsTooltipColor="toolTipColor">
			</VueSidebarMenuAkahon>
		</div>

		<v-navigation-drawer v-model="drawer" fixed temporary left style="
				background-color: #d31f2cba;
				position: fixed;
				opacity: 0.9;
				overflow-y: hidden;
				padding-top: 50px;
				z-index: 9999999;
				font-family: Sofia Pro;
			">
			<v-list dense>
				<v-list-item-group v-model="group" color="#fff">
					<router-link v-for="(mobileMenu, i) in itemsENG" :key="i" :to="{ name: mobileMenu.path }">
						<v-list-item class="my-10">
							<v-list-item-content>
								<v-list-item-title style="
										text-decoration: none;
										font-family: Sofia Pro;
										font-size: 22px;
										color: #fff !important;
										padding: 5px 0px !important;
									" v-text="mobileMenu.name"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</router-link>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<router-link to="/franquia">
			<v-btn v-if="!isHome && !isFranquia" class="btnFloat botaoMobile" style="
					background-color: #fff;
					color: #d31f2c;
					font-family: Sofia pro;
					font-weight: 700;
					border-radius: 15px;
					text-transform: inherit;
					z-index: 9;
					margin-top: 15rem !important;
					position: fixed;
					bottom: 0 !important;
					padding: 20px 3px;
					font-size: 15px !important;
					border: 1px solid #d4af37;
				">
				Seja um franqueado!</v-btn>
		</router-link>

		<router-link to="/curso">
			<v-btn v-if="isFranquia" class="btnFloat botaoMobile" style="
					background-color: #fff;
					color: #d31f2c;
					font-family: Sofia pro;
					font-weight: 700;
					border-radius: 15px;
					text-transform: inherit;
					z-index: 9;
					position: fixed;
					bottom: 0 !important;
					margin-top: 15rem !important;
					padding: 20px 3px;
					font-size: 15px !important;
					border: 1px solid #d4af37;
				">
				Estude conosco!</v-btn>
		</router-link>

		<v-main class="fundoHugle">
			<router-view />
		</v-main>

		<v-footer v-if="!isHome & !isTeste" style="margin: 0px; padding: 6px -1px; margin-top: 1px !important"
			class="wdFt padding">
			<v-row class="align-center mgFt columnFtMb corDoFundo" justify="center" style="
					margin-top: 0px !important;
					margin: -16px;
					font-family: sofia pro, Roboto;
				
				">
				<v-col style="padding: 50px 0px" cols="6" md="6" xs="6" class="d-flex justify-center align-center flex-column">
					<v-img style="padding: 30px 80px" contain width="100" src="./assets/hulge branco.webp"></v-img>
					<div style="margin-top: 25px">
						<a href="https://www.facebook.com/hugleeducation/" target="_blank">
							<v-icon class="socialIcons" style="color: #fff; padding: 5px" size="25">
								mdi-facebook</v-icon>
						</a>
						<a href="https://www.instagram.com/hugleeducation/" target="_blank">
							<v-icon class="socialIcons" style="color: #fff; padding: 5px" size="25">
								mdi-instagram</v-icon>
						</a>
						<a href="https://www.linkedin.com/company/hugleeducation/?trk=ppro_cprof&originalSubdomain=br"
							target="_blank">
							<v-icon class="socialIcons" style="color: #fff; padding: 5px" size="25">
								mdi-linkedin</v-icon>
						</a>
					</div>
				</v-col>
				<v-col style="padding: 0px 0px" cols="6" md="6" xs="6" class="flex-column align-center justify-center d-flex">
					<div>
						<router-link to="sac">
							<v-btn class="botaoEmailMobile" text style="
									font-family: usual;
									font-weight: 800;
									border-radius: 50px;
									font-size: 13px;
									padding: 30px 30px;
									color: #fff;
									box-shadow: none;
								">
								E-mail para sugestões, melhorias ou críticas<br />ouvidoria@hugle.com.br
							</v-btn>

							<a href="https://api.whatsapp.com/send?phone=5512981580749">
								<v-btn class="mgLeft" text style="
										font-family: usual;
										padding: 30px 30px;
										font-weight: 800;
										box-shadow: none;
										color: #d31f2c;
										background-color: #fff;
										border-radius: 20px;
										border: 2px solid #d4af37;
										margin-left: 85px;
									">
									Whatsapp da ouvidoria: <br />
									(12) 98158-0749</v-btn>
							</a>
						</router-link>
					</div>
				</v-col>

				<v-row class="align-center" justify="center" style="margin: 5px !important">
					<v-col cols="12" md="6" class="d-flex align-center justify-center">
						<p class="textoMob padTextoFt" style="
								color: #fff;
								font-family: sofia pro, Roboto;
								padding-left: 60px;
								margin-bottom:  0px !important;
							">
							Hugle - Todos direitos reservados. Site atualizado em 2022.
						</p>
					</v-col>
					<v-col cols="12" md="3" class="d-flex align-center justify-center ">
						<v-btn @click="politica = true" class="btnPolitica">Política de privacidade</v-btn>
					</v-col>
					<v-col cols="12" md="3" class="d-flex align-center justify-center padLogo">
						<a href="https://abaregroup.com/" target="_blank">
							<v-img width="85" style="margin-bottom: 20px" src="./assets/abareBranco.webp"></v-img>
						</a>
					</v-col>
				</v-row>
			</v-row>
		</v-footer>
		<v-dialog v-model="politica" style="z-index: 9999;">
			<v-row>
				<v-card style="padding: 40px">
					<h2><span style="color: rgb(68, 68, 68);">Política Privacidade</span></h2>
					<p><span style="color: rgb(68, 68, 68);">A sua privacidade é importante para nós. É política do Hugle respeitar
							a sua privacidade em relação a qualquer informação sua que possamos coletar no site <a
								href="https://hugle.com.br/">Hugle</a>, e outros sites que possuímos e operamos.</span></p>
					<p><span style="color: rgb(68, 68, 68);">Solicitamos informações pessoais apenas quando realmente precisamos
							delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e
							consentimento. Também informamos por que estamos coletando e como será usado.</span></p>
					<p><span style="color: rgb(68, 68, 68);">Apenas retemos as informações coletadas pelo tempo necessário para
							fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente
							aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não
							autorizados.</span></p>
					<p><span style="color: rgb(68, 68, 68);">Não compartilhamos informações de identificação pessoal publicamente ou
							com terceiros, exceto quando exigido por lei.</span></p>
					<p><span style="color: rgb(68, 68, 68);">O nosso site pode ter links para sites externos que não são operados
							por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos
							aceitar responsabilidade por suas respectivas&nbsp;</span><a href="https://politicaprivacidade.com/"
							rel="noopener noreferrer" target="_blank"
							style="background-color: transparent; color: rgb(68, 68, 68);">políticas de privacidade</a><span
							style="color: rgb(68, 68, 68);">.</span></p>
					<p><span style="color: rgb(68, 68, 68);">Você é livre para recusar a nossa solicitação de informações pessoais,
							entendendo que talvez não possamos fornecer alguns dos serviços desejados.</span></p>
					<p><span style="color: rgb(68, 68, 68);">O uso continuado de nosso site será considerado como aceitação de
							nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como
							lidamos com dados do usuário e informações pessoais, entre em contacto connosco.</span></p>
					<p><span style="color: rgb(68, 68, 68);">
							<ul>
								<li><span style="color: rgb(68, 68, 68);">O serviço Google AdSense que usamos para veicular publicidade
										usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de
										vezes que um determinado anúncio é exibido para você.</span></li>
								<li><span style="color: rgb(68, 68, 68);">Para mais informações sobre o Google AdSense, consulte as FAQs
										oficiais sobre privacidade do Google AdSense.</span></li>
								<li><span style="color: rgb(68, 68, 68);">Utilizamos anúncios para compensar os custos de funcionamento
										deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade
										comportamental usados por este site foram projetados para garantir que você forneça os anúncios mais
										relevantes sempre que possível, rastreando anonimamente seus interesses e apresentando coisas
										semelhantes que possam ser do seu interesse.</span></li>
								<li><span style="color: rgb(68, 68, 68);">Vários parceiros anunciam em nosso nome e os cookies de
										rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de
										um dos sites de nossos parceiros, para que possamos creditá-los adequadamente e, quando aplicável,
										permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma
										compra.</span></li>
							</ul>
						</span></p>
					<h3><span style="color: rgb(68, 68, 68);">Compromisso do Usuário</span></h3>
					<p><span style="color: rgb(68, 68, 68);">O usuário se compromete a fazer uso adequado dos conteúdos e da
							informação que o Hugle oferece no site e com caráter enunciativo, mas não limitativo:</span></p>
					<ul>
						<li><span style="color: rgb(68, 68, 68);">A) Não se envolver em atividades que sejam ilegais ou contrárias à
								boa fé a à ordem pública;</span></li>
						<li><span style="color: rgb(68, 68, 68);">B) Não difundir propaganda ou conteúdo de natureza racista,
								xenofóbica, </span><span style="color: rgb(33, 37, 41);"><a
									href='https://apostasonline.guru/bet365-apostas/'
									style='color:inherit !important; text-decoration: none !important; font-size: inherit !important;'>bet365
									brasil</a></span><span style="color: rgb(68, 68, 68);"> ou azar, qualquer tipo de pornografia ilegal, de
								apologia ao terrorismo ou contra os direitos humanos;</span></li>
						<li><span style="color: rgb(68, 68, 68);">C) Não causar danos aos sistemas físicos (hardwares) e lógicos
								(softwares) do Hugle, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos
								ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente
								mencionados.</span></li>
					</ul>
					<h3><span style="color: rgb(68, 68, 68);">Mais informações</span></h3>
					<p><span style="color: rgb(68, 68, 68);">Esperemos que esteja esclarecido e, como mencionado anteriormente, se
							houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados,
							caso interaja com um dos recursos que você usa em nosso site.</span></p>
					<p><span style="color: rgb(68, 68, 68);">Esta política é efetiva a partir de&nbsp;24 May 2023 13:06</span></p>
				</v-card>
			</v-row>
		</v-dialog>

		<v-snackbar v-model="snackBarPolitica" timeout="-1" color="#d31f2c">
			<span
				:class="intBreakPoint >= 2 ? 'd-flex align-center justify-center' : 'd-flex flex-column align-center justify-center'"
				style="gap: 0.5rem">Ao
				aceitar, você aceita nossos <v-btn @click="politica = true" light style="font-size: 0.8rem;">Termos e
					Condições</v-btn> <v-btn @click="aceitarTermos" text class="ma-1">
					Eu Aceito.
				</v-btn> </span>
		</v-snackbar>
	</v-app>
</template>


<script>
import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";

export default {
	name: "App",
	components: { VueSidebarMenuAkahon },
	data: () => ({
		titulo: "",
		group: {},
		snackBarPolitica: true,
		politica: false,
		key: "",
		isMenuOpen: false,
		logo: "@/assets/hulge branco.webp",
		drawer: false,
		Cor: "#d31f2cba",
		sCor: "#d31f2cba",
		fontCor: "#fff",
		fontIcon: "#fff",
		hCor: null,
		toolTipColor: "#d31f2cba",
		selecao: "",

		items: [
			// adicionado espaço no final da string de alguns itens para não ter string identica
			// se alguma coisa se repetir, item não aparece no v-select.
			{
				cidade: "Camboriú",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5547999660754",
			},
			{
				cidade: "Campinas",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5519989818148",
			},
			{
				cidade: "Curitiba",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5512992606836",
			},
			{
				cidade: "SJC - Parque Industrial",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5512982070467",
			},
			{
				cidade: "SJC - Jardim Aquarius",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5512996610866",
			},
			{
				cidade: "SJC - Santana",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5512991465365",
			},
			{
				cidade: "SJC- Vila Industrial",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5512982070468",
			},
			{
				cidade: "SJC- Vista Verde",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5512981323113",
			},
			{
				cidade: "Nova Serrana",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=553791960343",
			},
			{
				cidade: "Piracicaba",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5512992606836 ",
			},
			{
				cidade: "São Sebastião",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5512974086467",
			},
			{
				cidade: "Taubaté",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5512982640048",
			},
			{
				cidade: "Niteroi",
				img: "bx bxl-whatsapp",
				telefone: "https://wa.me/5521969227448?text=Gostaria+de+saber+mais+sobre+o+m%C3%A9todo+para+aprender+ingl%C3%AAs+4x+mais+r%C3%A1pido",
			},
			{
				cidade: "Vila Velha",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5527995012544",
			},
			{
				cidade: "Outras Unidades",
				img: "bx bxl-whatsapp",
				telefone: "https://api.whatsapp.com/send?phone=5512992606836  ",
				// adicionado espaço no final da string para não ser repetida
				// */ se alguma coisa se repetir, item não aparece no v-select.
				// gds
			},
		],

		Itens: [
			{
				link: "/",
				name: "Início",
				tooltip: "Início",
				icon: "bx bxs-home",
				textTool: "Inicio",
			},
			{
				link: "/curso",
				name: "A Hugle",
				tooltip: "Cursos",
				icon: "bx bxs-building",
				textTool: "Inicio",
			},
			{
				link: "/escolas",
				name: "Nossas unidades",
				tooltip: "Nossas Unidades",
				icon: "bx bxs-graduation",
				textTool: "Inicio",
			},
			{
				link: "/trabalheConosco",
				name: "Trabalhe Conosco",
				tooltip: "Trabalhe conosco",
				icon: "bx bxs-briefcase",
				textTool: "Inicio",
			},
			{
				link: "/sac",
				name: "SAC",
				tooltip: "SAC",
				icon: "bx bxs-chat",
				textTool: "Inicio",
			},
		],

		dialog: false,
		itemsENG: [
			{ name: "Início", path: "ahugle" },
			{ name: "Curso", path: "curso" },
			{ name: "Nossas unidades", path: "escolas" },
			{ name: "Trabalhe Conosco", path: "trabalheConosco" },
			{ name: "SAC", path: "sac" },
		],
	}),

	methods: {
		changeRoute(link) {
			window.open(link, "_blank");
		},

		mouseover: function () {
			this.isMenuOpen = true;
		},
		mouseleave: function () {
			this.isMenuOpen = false;
		},

		aceitarTermos() {
			this.snackBarPolitica = false;
			sessionStorage.setItem("termos", this.snackBarPolitica);
		},
		checkTermos() {
			if (sessionStorage.getItem("termos") == "false") {
				this.snackBarPolitica = false;
			} else {
				this.snackBarPolitica = true;
			}
		},
	},

	mounted() {
		this.checkTermos();
	},

	computed: {
		isHome() {
			return this.$route.name == "paginaInicio";
		},

		isTeste() {
			return this.$route.name == "teste";
		},

		isTrabalho() {
			return this.$route.name == "trabalheConosco";
		},

		isFranquia() {
			return this.$route.name == "franquia";
		},

		intBreakPoint() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 0;
				case "sm":
					return 1;
				case "md":
					return 2;
				case "lg":
					return 3;
				case "xl":
					return 4;
				default:
					return 0;
			}
		},
	},
};
</script>

<style>
.btnPolitica {
	background-color: transparent !important;
	letter-spacing: normal !important;
	box-shadow: none !important;
	color: #fff !important;
}

.corDoFundo {
	background: linear-gradient(148deg, rgba(167, 18, 19, 1) 0%, rgba(223, 57, 57, 1) 100%, rgba(255, 255, 255, 1) 100%) !important;
}

@font-face {
	font-family: "Roboto";

	src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
	font-display: swap;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Sofia pro";

	src: url("./assets/fonts/Sofia Pro Regular Az.otf") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

#my-scroll {
	width: 270px !important;
	height: 320px !important;
}

.sidebar li .tooltip {
	left: calc(100% - 185px) !important;
	color: white;
}

@font-face {
	font-family: "Sofia pro";

	src: url("./assets/fonts/Sofia Pro Medium Az.otf") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: "Sofia pro";

	src: url("./assets/fonts/Sofia Pro Black Az.otf") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 800;
	font-stretch: normal;
}

body {
	padding-left: 0px !important;
}

@media (min-device-width: 360px) and (max-device-width: 731px) {
	.disableMobile {
		display: none !important;
	}

	.mgLeft {
		margin-left: 0px !important;
	}

	.botaoEmailMobile {
		padding: 0px 0px !important;
		padding-left: 150px !important;
		width: 50px !important;
		font-size: 11px !important;
	}

	.columnFtMb {
		flex-direction: column !important;
	}

	.textoMob {
		padding-left: 10px !important;
	}

	.padding {
		padding: 6px 8px !important;
		margin-left: -40px !important;
	}

	.padTextoFt {
		padding-left: 50px !important;
	}

	.mgFt {
		margin: -8px !important;
	}

	.padLogo {
		padding-left: 45px !important;
	}

	.padMobile {
		padding: 50px 20px !important;
	}
}

#my-scroll {
	margin: 0px -5px 0px !important;
}

.botaoMobile {
	position: fixed !important;
	right: 0px !important;
	font-size: 12px !important;
	z-index: 99999 !important;
	border-radius: 20px 0 0 20px !important;
	background-color: rgba(172, 10, 10, 0.9) !important;
	color: #fff !important;
	font-family: Poppins !important;
	top: 300px !important;
	padding: 7px 2px;
	font-weight: 300 !important;
}

.sidebar.open {
	width: 255px !important;
}

.sidebar {
	left: -22px !important;
	width: 78px !important;
	display: initial !important;
}

.sidebar li a {
	display: flex;
	height: 100%;
	width: 50% !important;
	/* border-radius: 12px; */
	align-items: center;
	text-decoration: none;
	transition: all 0.4s ease;
	background: transparent;
}

.sidebar div.profile {
	position: relative;
	height: 60px;
	width: 78px;
	padding: 10px 14px;
	background: transparent;
	transition: all 0.5s ease;
	overflow: hidden;
}

.v-text-field--placeholder {
	color: #fff !important;
}

.socialIcons {
	opacity: 0.9 !important;
	color: #ffffff !important;
}

.socialIcons:hover {
	opacity: inherit !important;
	font-size: 46px !important;
	transition: 0.2s;
}

.sidebar .logo-details #btn {
	right: -9px;
}

.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
	color: #fff !important;
	font-weight: 600 !important;
	padding-bottom: 20px !important;
}

i.bx.bxs-user-rectangle {
	display: none;
}

.alinhadoM {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

i.bx.icon.bxl-c-plus-plus {
	display: none;
}

.v-application ul,
.v-application ol {
	padding-left: 27px !important;
}

a {
	text-decoration: none;
	width: auto !important;
}

.v-list:not(.v-sheet--outlined) {
	box-shadow: 0 0 0 0rgba (0, 0, 0, 0.2), 0 0 0 0rgba (0, 0, 0, 0.14),
		0 0 0 0rgba (0, 0, 0, 0.12);
	background-color: #ba3039 !important;
}

.v-list-item__title {
	color: #fff !important;
	font-weight: 800 !important;
	padding-bottom: 30px !important;
}

.btnFloat {
	position: fixed !important;
	right: -1px !important;
	font-size: 12px !important;
	border-radius: 20px 0 0 20px !important;
	background-color: rgba(172, 10, 10, 0.9) !important;
	color: #fff !important;
	font-family: Poppins !important;
	top: 150px !important;
	padding: 7px 2px;
	font-weight: 700 !important;
}

.fundoHugle {
	background-image: url("./assets/capahugle.webp");
	background-repeat: no-repeat !important;
	background-size: cover;
	background-attachment: fixed;
	background-position: center-top;
}

.v-input__slot {
	box-shadow: none !important;
	background-color: #ba303a5c;
}

.custom-label-color .v-label {
	color: #d31f2c !important;
	opacity: 1;
}

.v-tabs-bar {
	background-color: transparent !important;
}

/* .v-input__prepend-outer {
    margin-top: 12px !important;
    z-index: 99 !important;
    margin-right: -36px !important;
} */

.btnMenu {
	font-family: "Poppins" !important;
	font-weight: 300 !important;
	box-shadow: none !important;
	border: none !important;
	background-color: transparent !important;
}

.btnMenu:hover {
	color: #769d4a !important;
	font-family: "Poppins" !important;
	font-weight: 300 !important;
	box-shadow: none !important;
	border: none !important;
}

.padFooter {
	padding: 10px 10px !important;
}
</style>
